import { useQuery } from "@apollo/react-hooks"
import { gql } from "graphql-tag"

const GET_TICKETS = gql`
  query GetTicketsByCategory($input: TicketsByCategory!) {
    getTicketsByCategory(input: $input) {
      id
      name
      price
      track_price
      type
      fee
      description
      is_featured
      is_soldout
    }
  }
`

export function useGetTickets(options = {}, isTicketHoss = true) {
  return useQuery(GET_TICKETS, {
    variables: { input: options },
    skip: isTicketHoss,
  })
}
