import styled from "styled-components"

export const EventMainImageContainer = styled.div`
  position: relative;

  @media (max-width: 768px) {
    max-width: 500px;
    margin-inline: auto;
  }
`

export const Image = styled.img`
  object-fit: fill;
  border-radius: 20px;
  width: 375px;
  height: 230px;
  min-width: 260px;
  opacity: 1;

  @media (max-width: 768px) {
    width: 100%;
    height: 240px;
    border-radius: 0px;
    max-width: 500px;
  }
`

export const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 0px;
  left: 20px;

  border: ${props =>
    props.status === "cancelled" || props.status === "postponed"
      ? "1px solid #F62F2F"
      : "none"};
  border-radius: 8px;
  padding: ${props =>
    props.status === "cancelled" || props.status === "postponed" ? "6px" : ""};
  margin-inline: ${props =>
    props.status === "cancelled" || props.status === "postponed" ? "-8px" : ""};
  width: 88%;

  @media (max-width: 768px) {
    width: 92%;
  }
`

export const EventName = styled.span`
  text-align: start;
  letter-spacing: 0;
  font-weight: 700;
  font-size: 2em;
  text-shadow: 0px 2px 4px rgba(14, 14, 44, 0.1);
  color: #ffffff;
  position: relative;
  max-width: 342px;
  line-height: 40px;
`

export const Support = styled("div")({
  display: "flex",
  "align-items": "center",
  "flex-shrink": "0",
  "align-self": "stretch",
  "border-color": "transparent",
  position: "relative",
  marginLeft: 28,
  marginTop: 12,
})

export const LocationIcon = styled("img")({
  width: "20px",
  height: "14px",
  "margin-right": "4px",
})

export const LocationText = styled("span")({
  color: "white",
  height: "auto",
  "text-align": "left",
  "margin-right": "0",
  "margin-bottom": "0",
  "align-self": "auto",
  "flex-grow": 1,
  fontSize: "0.875em",
  lineHeight: "16px",
  fontWeight: 700,
})

export const EventLogo = styled.img`
  border-color: transparent;
  object-fit: contain;
  width: 110px;
  height: 48.96px;
  margin-left: 15px;
`

export const EventLogoWrapper = styled.div`
  max-width: 35%;
  height: 45px;
  position: absolute;
  display: flex;
  align-items: flex-start;
  left: 35%;
  top: 40px;
  flex-grow: 1;
  flex-shrink: 0;
`
export const ImageOverlayWrapper = styled.div`
  inset: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: 375px;
  height: 230px;

  @media (max-width: 768px) {
    width: 100%;
    height: 240px;
    border-radius: 0px;
  }
`
