import { Link } from "gatsby"
import styled from "styled-components"

export const MainContainer = styled.div`
  justify-content: space-between;
  margin-top: 32px;
  margin-inline: 0px;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 325px;
    justify-content: start;
    margin-top: 16px;
    padding-bottom: 10px;
  }
`
export const MobileMainContainer = styled.div`
  justify-content: space-between;
  margin-top: 25px;
  margin-inline: 0px;
`

export const DateContainer = styled.div`
  background-color: #fa4616;
  width: 114px;
  height: 104px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 6px;
  gap: 1px;
  margin-top: 0px;

  @media (max-width: 768px) {
    width: 72px;
    height: 68px;
  }
`

export const DateContainerMobile = styled.div`
  background-color: #fa4616;
  width: 114px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 1px;
  margin-top: 0px;
`

export const Day = styled.p`
  text-align: center;
  padding: 8px 5px 2px;
  gap: 10px;
  width: 88px;
  height: 49px;
  background: #ffffff;
  border-radius: 5px;
  color: #000033;
  font-weight: 700;
  font-size: 1.4em;

  @media (max-width: 768px) {
    width: 50px;
    height: 32px;
    font-size: 1.1em;
    padding-top: 0px;
  }
`

export const DayMobile = styled.div`
  text-align: center;
  padding: 0px 8px 2px;
  width: 40px;
  height: 22px;
  background: #ffffff;
  border-radius: 4px;
  color: #000033;
  font-weight: 700;
  font-size: 1.1em;
`

export const Date = styled.p`
  font-weight: 700;
  font-size: 1.4em;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 1.1em;
    padding-left: 3px;
  }
`

export const NameContainer = styled.div`
  font-weight: 500;
  font-size: 1.5em;
  color: #ffffff;
  margin-top: 8px;
  justify-content: space-between;

  @media (max-width: 768px) {
    min-width: 107px;
    font-size: 1em;
    justify-content: start;
    padding-left: 12px;
    max-width: 107px;
    width: 100%;
    padding-right: 0;
    margin-top: 3px;
  }
`

export const MobileNameContainer = styled.div`
  font-weight: 500;
  font-size: 1.5em;
  color: #ffffff;
  padding-top: 15px;
  margin-top: 0px;
  padding-inline: 0px;
`

export const EventDetailButton = styled(Link)`
  font-weight: 700;
  font-size: 1em;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-bottom: 1px solid #fe5000;
  margin-left: 15px;
  margin-top: 15px;
  max-width: 90px;
  margin-inline: auto;

  @media (max-width: 768px) {
    display: none;
  }
`

export const ButtonsContainer = styled.div`
  min-width: 160px;
  max-width: 160px;
  margin-top: 0px;
  width: 20%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const ArrowRight = styled.img`
  max-height: 12px;
  vertical-align: middle;
  margin-top: 35px;

  @media (min-width: 768px) {
    display: none;
  }
`

export const DateAndName = styled.div`
  width: ${props => (props.isMainEvent ? "70%" : "55%")};
  margin-top: 0px;
  margin-inline: 0px;

  @media (max-width: 768px) {
    width: 68%;
    padding: 5px;
  }
`

export const ScheduleTime = styled.p`
  font-weight: 700;
  font-size: 1em;
  line-height: 24px;
  color: #eaeaea;
  margin: 0px;
  margin-bottom: 16px;
  font-family: "Roboto";

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`
export const ScheduleListContainer = styled.div`
  margin: 15px 0px;

  @media (max-width: 768px) {
    max-width: 250px;
  }
`
