import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import Mail from "../images/mail.svg"
import Globe from "../images/globe_white.svg"
import Button from "../components/Button"
import Accordion from "../components/Accordion"
import TrackTickets from "../components/tickets/TrackTickets"
import Arrow from "../images/arrow_orange.svg"
import {
  BreadCrumb,
  ShareButton,
  TrackHeaderWrapper,
  DownloadText,
  VideoPlayer,
  VideoPlayerMini,
  HaveQuestion,
  InfoIcon,
  ContactList,
  TicketsImage,
  PromoImages,
  EventBodyContainer,
  PromoContainer,
  EventInfoWrapper,
  ScheduleWrapper,
  DownloadAppText,
  PromoColContainer,
  EventScheduleWrapper,
  SocialLinkWrapper,
  SocialLink,
  FaqQuestion,
  FaqAnswer,
  UserCountWrapper,
  SeriesCarouselWrapper,
  PitpayTicketsImage,
  PitpayDownloadText,
  PitpayAnnualTicketsImage,
  PitpayAnnualImage,
  ArrowRight,
  PitpayAnnualTicketText,
  PitpayAnnualTicketTextContainer,
  ScheduleWrapperMobile,
  DownloadContainer,
  DetailStyle,
} from "../styles/detail.style"
import ScheduleList from "../components/event/ScheduleList"
import ScheduleDetail from "../components/event/ScheduleDetail"
import { InfoContainer } from "../styles/accordion.style"
import Flag from "../images/flag.svg"
import Person from "../images/UserIcon.svg"
import Mobile from "../images/phone_white.svg"
import EventImage from "../components/event/EventImage"
import Tickets from "../images/tickets_image.svg"
import TicketsNotify from "../images/tickets_notify_me.svg"
import TicketsNoSale from "../images/tickets_no_sale.svg"
import Race from "../images/race.svg"
import Other from "../images/Other.svg"
import GetPasses from "../images/GetPasses.svg"
import GetTickets from "../images/GetTickets.svg"
// import Food from "../images/food.svg"
import Sponsor from "../images/sponsor.svg"
import SponsorRectangle from "../images/sponsor_rectangle.svg"
// import Merch from "../images/merch.svg"
import PitPass from "../images/pit_passes.svg"
import PitPassRectangle from "../images/pitpay_rectangle.svg"
import Carousel from "../components/Carousel"
import { useMediaQuery } from "react-responsive"
import DownloadApp from "../components/DownloapAppModal"
import InfoImage from "../images/info_icon.svg"
import FaqImage from "../images/FAQ.svg"
import Instagram from "../images/Instagram_orange.svg"
import Facebook from "../images/Facebook_orange.svg"
import Twitter from "../images/Twitter_orange.svg"
import EventInfoModal from "../components/EventInfoModal"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { useGetTickets } from "../gql/useGetTickets"
import { ReplaceEmailPhoneWithLink } from "../utils/emailAndPhoneReplacer"

export default function EventDetails({ data }) {
  const event = data.tickethossGraphql.getEvent
  const tickets = data.tickethossGraphql.getGATickets
  const sponsorships = data.tickethossGraphql.getEventSponsorships

  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })

  const [type, setType] = useState("ticket")
  const [name, setName] = useState("ticket")
  const [categoryId, setCategoryId] = useState()

  const { loading, data: ticketData } = useGetTickets(
    {
      event_id: event.id,
      type,
      ...(categoryId ? { category_id: categoryId } : null),
    },
    isTickethoss
  )

  const pitpayTickets =
    !loading && ticketData ? ticketData.getTicketsByCategory : []

  const [open, setOpen] = useState()
  const toggleTicketModal = () => {
    if (isMobile && !open) document.body.style.overflow = "hidden"
    else if (isMobile && open) document.body.style.overflow = "visible"
    setOpen(open => !open)
  }

  const togglePitpayTicketModal = (type, categoryId, ticketName) => {
    setType(type)
    setName(ticketName)
    if (categoryId) setCategoryId(categoryId)

    if (isMobile && !open) document.body.style.overflow = "hidden"
    else if (isMobile && open) document.body.style.overflow = "visible"
    setOpen(open => !open)
  }

  const [openDownloadApp, setOpenDownloadApp] = useState(false)
  const openDownloadModal = () => {
    document.body.style.overflow = "hidden"
    setOpenDownloadApp(true)
  }

  const link = event.track && event.track.url ? event.track.url : ""
  let frameLink = link
  if (
    link.includes("youtube") ||
    link.includes("http://youtu.be") ||
    link.includes("https://youtu.be")
  ) {
    const videoCode = link.split("/")[3]
    frameLink = `https://www.youtube.com/embed/${
      videoCode.includes("watch?v=")
        ? videoCode.replace("watch?v=", "")
        : videoCode
    }`
  }

  const onClose = () => {
    document.body.style.overflow = "visible"
    setOpenDownloadApp(false)
  }

  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [eventData, setEventData] = useState()
  const openInfo = (eventId, promoterPhone, promoterEmail, status) => {
    if (!promoterPhone) {
      navigate(`/event/${eventId}`)
    } else {
      setEventData({
        eventLink: `/event/${eventId}`,
        promoterPhone,
        promoterEmail,
        status,
      })
      if (isMobile) document.body.style.overflow = "hidden"
      setOpenInfoModal(`/event/${eventId}`)
    }
  }
  const onCloseInfo = () => {
    if (isMobile) document.body.style.overflow = "visible"
    setOpenInfoModal(false)
  }

  const defaultFacebookImage =
    event.series.length > 0 && event.series[0]?.facebook_image
      ? event.series[0].facebook_image
      : event.track && event.track.facebook_image
      ? event.track.facebook_image
      : event.track.image
      ? event.track.image
      : event.image

  const defaultTwitterImage =
    event.series.length > 0 && event.series[0]?.twitter_image
      ? event.series[0].twitter_image
      : event.track && event.track.twitter_image
      ? event.track.twitter_image
      : event.track.image
      ? event.track.image
      : event.image

  const defaultInfo =
    event.series.length > 0 && event.series[0]?.bio
      ? event.series[0].bio
      : event.track?.bio

  const displayGetTickets = isTickethoss
    ? event.show_pitpay
    : event.show_tickethoss

  const tickethossLink = "https://gettickethoss.com/l0n"
  const pitpayLink = "https://getpitpay.com/5lb"

  return (
    <Layout>
      <Helmet>
        <meta property="og:title" content={event.name} />
        <meta
          property="og:description"
          content={event.about ? event.about : defaultInfo}
        />
        <meta
          property="og:image"
          content={
            event.facebook_image ? event.facebook_image : defaultFacebookImage
          }
        />
        <meta property="og:image:width" content="600px" />
        <meta property="og:image:height" content="314px" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={event.name} />
        <meta name="twitter:site" content="@PitPayApp"></meta>
        <meta
          property="twitter:description"
          content={event.about ? event.about : defaultInfo}
        />
        <meta
          property="twitter:image"
          content={
            event.twitter_image ? event.twitter_image : defaultTwitterImage
          }
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Event",
            name: event.name,
            startDate: event.start_date,
            endDate: event.end_date,
            image: event.image,
            location: {
              "@type": "Place",
              name: event.track.name,
              address: {
                "@type": "PostalAddress",
                streetAddress: event.track.city,
                addressLocality: event.track.state,
                addressRegion: event.track.state,
              },
            },
            description: event.about,
          })}
        </script>
      </Helmet>
      <TrackHeaderWrapper className="Row" isEvent={true}>
        <div className="Col">
          <BreadCrumb>
            <Link to="/events" style={{ color: "#888888" }}>
              Events
            </Link>
            <span style={{ color: "#888888" }}> / </span>
            <span style={{ color: "#FA4616" }}>{event.name}</span>
          </BreadCrumb>
        </div>
        <div className="Col"></div>
        <ShareButton className="Col" style={{ marginTop: 5 }}></ShareButton>
      </TrackHeaderWrapper>

      <EventBodyContainer className="Row">
        <EventInfoWrapper className="Col">
          <Carousel isEvent={true}>
            <div className="btns">
              <EventImage
                image={event.image}
                name={event.name}
                logo={event.logo}
                location={event.track ? `${event.track.name}` : null}
                startDate={event.start_date}
                endDate={event.end_date}
                isMultiDay={event.isMultiDay}
                status={event.status}
                event={event}
                openInfo={openInfo}
              />

              <UserCountWrapper>
                <Button
                  text="Interested?"
                  style={{
                    minWidth: 160,
                    height: 40,
                    padding: "9px",
                    fontSize: "1em",
                  }}
                  minHeight="40px"
                />
                <Button
                  text="Going?"
                  style={{
                    minWidth: 160,
                    height: 40,
                    padding: "9px",
                    marginLeft: 8,
                    fontSize: "1em",
                  }}
                  minHeight="40px"
                />
              </UserCountWrapper>

              {/* <UserCountWrapper>
                <InterestedWrapper>
                  <span style={{ color: "#fa4616" }}>204</span> Interested{" "}
                </InterestedWrapper>
                <Divider />
                <InterestedWrapper>
                  <span style={{ color: "#fa4616" }}>547</span> Going{" "}
                </InterestedWrapper>
              </UserCountWrapper> */}
            </div>

            {event.series &&
              event.series.length > 0 &&
              event.series.map(
                series =>
                  series && (
                    <div className="btns" key={series.id}>
                      <EventImage
                        image={series.image}
                        name={series.name}
                        logo={series.logo}
                        location={
                          event.track
                            ? `${event.track.city} ${event.track.state}`
                            : null
                        }
                        startDate={event.start_date}
                        endDate={event.end_date}
                        isMultiDay={event.isMultiDay}
                        isSeries={true}
                      />
                      <SeriesCarouselWrapper>
                        <Link to={`/series/${series.id}`}>
                          <Button
                            text="Series Detail"
                            style={{
                              minWidth: "100%",
                              height: 40,
                              padding: "9px",
                              fontSize: "1em",
                              marginTop: 20,
                            }}
                            minHeight="40px"
                          />
                        </Link>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "1em",
                            fontWeight: 500,
                          }}
                        >
                          View details about the series
                        </p>
                      </SeriesCarouselWrapper>
                    </div>
                  )
              )}
          </Carousel>
          <PromoColContainer className="Col">
            {isTickethoss ? (
              <DownloadContainer>
                {event.sales_status !== "cancelled" && (
                  <DownloadAppText
                    style={{
                      maxWidth: 375,
                      paddingTop: event.series.length > 0 ? "33px" : "3px",
                    }}
                  >
                    Download App to Purchase
                  </DownloadAppText>
                )}
                {event.sales_status === "open" ? (
                  <TicketsImage
                    src={Tickets}
                    alt="Tickets"
                    onClick={toggleTicketModal}
                  />
                ) : event.sales_status === "closed" ? (
                  <TicketsImage src={TicketsNoSale} alt="Tickets" />
                ) : event.sales_status === "future" ? (
                  <TicketsImage
                    src={TicketsNotify}
                    alt="Tickets"
                    onClick={openDownloadModal}
                  />
                ) : null}
              </DownloadContainer>
            ) : (
              <>
                {(event.purchase_options?.has_tickets ||
                  event.purchase_options?.has_memberships ||
                  event.purchase_options?.has_pitpasses) && (
                  <>
                    <DownloadAppText
                      style={{
                        paddingTop: event.series.length > 0 ? "33px" : "3px",
                      }}
                    >
                      Available for the event
                    </DownloadAppText>
                    <PitpayDownloadText>
                      Download the app and select everything needed for this
                      event.
                    </PitpayDownloadText>
                  </>
                )}
                {/* {event.purchase_options?.has_tickets && (
                  <PitpayAnnualTicketsImage
                    onClick={() => togglePitpayTicketModal("ticket")}
                  >
                    <PitpayAnnualTicketTextContainer>
                      <PitpayAnnualImage
                        key={"Pitpasses"}
                        src={Race}
                        alt={"Pitpasses"}
                      />
                      <PitpayAnnualTicketText>
                        {isTickethoss ? "Tickets" : "Pit Passes"}
                      </PitpayAnnualTicketText>
                    </PitpayAnnualTicketTextContainer>
                    <ArrowRight src={Arrow} alt="Arrow Right" />
                  </PitpayAnnualTicketsImage>
                )}
                {event.purchase_options?.has_other_tickets && (
                  <PitpayAnnualTicketsImage
                    onClick={() =>
                      togglePitpayTicketModal("other", "", "Other")
                    }
                  >
                    <PitpayAnnualTicketTextContainer>
                      <PitpayAnnualImage
                        key={"Other"}
                        src={Other}
                        alt={"Other"}
                      />
                      <PitpayAnnualTicketText>
                        {isTickethoss ? "Other" : "Other Options"}
                      </PitpayAnnualTicketText>
                    </PitpayAnnualTicketTextContainer>
                    <ArrowRight src={Arrow} alt="Arrow Right" />
                  </PitpayAnnualTicketsImage>
                )} */}
                {event.pass_options_v2 &&
                  event.pass_options_v2.length > 0 &&
                  event.pass_options_v2.map((ticket, index) => (
                    <PitpayAnnualTicketsImage
                      onClick={() =>
                        togglePitpayTicketModal(
                          ticket.type,
                          ticket.id,
                          ticket.text
                        )
                      }
                    >
                      <PitpayAnnualTicketTextContainer>
                        <PitpayAnnualImage
                          key={index}
                          src={ticket.icon}
                          alt={ticket.text}
                        />
                        <PitpayAnnualTicketText>
                          {ticket.text}
                        </PitpayAnnualTicketText>
                      </PitpayAnnualTicketTextContainer>
                      <ArrowRight src={Arrow} alt="Arrow Right" />
                    </PitpayAnnualTicketsImage>
                  ))}

                {displayGetTickets && (
                  <>
                    <DownloadAppText
                      style={{
                        paddingTop: event.series.length > 0 ? "33px" : "3px",
                        marginBottom: 2,
                        marginTop: 35,
                      }}
                    >
                      {isTickethoss ? "Pit Passes" : "Tickets"}
                    </DownloadAppText>
                    <a
                      href={isTickethoss ? pitpayLink : tickethossLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PitpayTicketsImage
                        src={isTickethoss ? GetPasses : GetTickets}
                        alt="Get Passes"
                        // onClick={toggleTicketModal}
                      />
                    </a>
                  </>
                )}
              </>
            )}
            <PromoContainer>
              {sponsorships && sponsorships.length > 0 && event.show_pitpay && (
                <PromoImages
                  src={Sponsor}
                  alt="Sponsor"
                  onClick={openDownloadModal}
                />
              )}
              {sponsorships &&
                sponsorships.length > 0 &&
                !event.show_pitpay && (
                  <PromoImages
                    src={SponsorRectangle}
                    alt="Sponsor"
                    onClick={openDownloadModal}
                    isFull={true}
                  />
                )}
              {event.show_pitpay && sponsorships && sponsorships.length > 0 && (
                <PromoImages
                  src={PitPass}
                  alt="PitPass"
                  style={{ marginLeft: 16 }}
                  onClick={openDownloadModal}
                />
              )}
              {event.show_pitpay && sponsorships.length === 0 && (
                <PromoImages
                  src={PitPassRectangle}
                  alt="Sponsor"
                  onClick={openDownloadModal}
                  isFull={true}
                />
              )}
            </PromoContainer>
            {/*<PromoContainer>
            //   <PromoImages
            //     src={Merch}
            //     alt="Merch"
            //     onClick={openDownloadModal}
            //   />
            // <PromoImages src={Food} alt="Food" onClick={openDownloadModal} />
            //   <PromoImages
            //     src={PitPass}
            //     alt="PitPass"
            //     style={{ marginLeft: 16 }}
            //     onClick={openDownloadModal}
            //   />
            // </PromoContainer>*/}
          </PromoColContainer>
        </EventInfoWrapper>

        <EventScheduleWrapper className="Col">
          <div>
            {event.admin_times && event.admin_times.length > 0 && (
              <div>
                <DownloadText>Event Schedule</DownloadText>
                <ScheduleWrapperMobile>
                  <Carousel>
                    {event.admin_times?.map((schedule, index) => (
                      <div className="btns" key={index}>
                        <ScheduleList
                          key={index}
                          date={schedule.event_date}
                          endDate={schedule.end_date}
                          gateTime={schedule.gate_time}
                          startTime={schedule.start_time}
                          isMultiday={schedule.isMultiDay}
                        />
                      </div>
                    ))}
                  </Carousel>
                </ScheduleWrapperMobile>
                <ScheduleWrapper>
                  {event.admin_times.map((schedule, index) => {
                    return (
                      <ScheduleList
                        key={index}
                        date={schedule.event_date}
                        endDate={schedule.end_date}
                        gateTime={schedule.gate_time}
                        startTime={schedule.start_time}
                        isMultiday={schedule.isMultiDay}
                      />
                    )
                  })}
                </ScheduleWrapper>
              </div>
            )}
          </div>

          {event.schedule_details && (
            <ScheduleDetail scheduleDetails={event.schedule_details} />
          )}

          {event.track && frameLink ? (
            <VideoPlayer
              src={frameLink}
              title={event.track.name}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : null}

          {frameLink ? (
            <VideoPlayerMini
              src={frameLink}
              title={event.track.name}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : null}
          <DetailStyle>
            {event.about && (
              <Accordion
                name="Event Info"
                icon={InfoImage}
                text={ReplaceEmailPhoneWithLink(event.about)}
              />
            )}
            {(event.instagram || event.twitter || event.facebook) && (
              <Accordion
                name="Let's Connect"
                icon={InfoImage}
                text={
                  <SocialLinkWrapper className="Row" style={{ marginTop: 0 }}>
                    {event.facebook && (
                      <SocialLink
                        href={`https://www.facebook.com/${event.facebook}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginTop: 0 }}
                      >
                        <img src={Facebook} alt="App Store" />
                      </SocialLink>
                    )}
                    {event.instagram && (
                      <SocialLink
                        href={`https://www.instagram.com/${event.instagram}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginTop: 0 }}
                      >
                        <img src={Instagram} alt="Instagram" />
                      </SocialLink>
                    )}
                    {event.twitter && (
                      <SocialLink
                        href={`https://twitter.com/${event.twitter}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginTop: 0 }}
                      >
                        <img src={Twitter} alt="Twitter" />
                      </SocialLink>
                    )}
                  </SocialLinkWrapper>
                }
              />
            )}
            {event.faqs && event.faqs.length > 0 && (
              <Accordion
                name="FAQ"
                icon={FaqImage}
                text={
                  <>
                    {event.faqs.map(faq => {
                      return (
                        <div className="Col" key={faq.id}>
                          <FaqQuestion>{faq.question}</FaqQuestion>
                          <FaqAnswer>{faq.answer}</FaqAnswer>
                        </div>
                      )
                    })}
                  </>
                }
              />
            )}
            {event.track && (
              <Accordion
                name="Track Details"
                icon={Flag}
                link={`/track/${event.track.id}`}
                target="_self"
              />
            )}
            {event.infos.map(info => (
              <Accordion
                key={info.id}
                name={info.info.name}
                icon={info.info.icon}
                text={ReplaceEmailPhoneWithLink(info.text)}
                logo={info.logo}
                link={info.link}
              />
            ))}
          </DetailStyle>

          <HaveQuestion>
            Have A Question? Contact the Event Organizer.
          </HaveQuestion>
          {event.track && event.track.name && (
            <InfoContainer>
              <InfoIcon src={Flag} alt="Flag" />
              <ContactList>{event.track.name}</ContactList>
            </InfoContainer>
          )}
          {event.promoter_name && (
            <InfoContainer>
              <InfoIcon src={Person} alt="Promoter" />
              <ContactList>{event.promoter_name}</ContactList>
            </InfoContainer>
          )}
          {event.promoter_phone && (
            <InfoContainer>
              <InfoIcon src={Mobile} alt="Mobile" />
              <a
                href={`tel: ${event.promoter_phone}`}
                target="_blank"
                rel="noreferrer"
              >
                <ContactList style={{ fontFamily: "Roboto", color: "#fa4616" }}>
                  {event.promoter_phone}
                </ContactList>
              </a>
            </InfoContainer>
          )}
          {event.promoter_email && (
            <InfoContainer>
              <InfoIcon src={Mail} alt="Mail" />
              <a
                href={`mailto: ${event.promoter_email}`}
                target="_blank"
                rel="noreferrer"
              >
                <ContactList>{event.promoter_email}</ContactList>
              </a>
            </InfoContainer>
          )}
          {event.promoter_website && (
            <InfoContainer>
              <InfoIcon src={Globe} alt="Site" />
              <a href={event.promoter_website} target="_blank" rel="noreferrer">
                <ContactList>{event.promoter_website}</ContactList>
              </a>
            </InfoContainer>
          )}
        </EventScheduleWrapper>
      </EventBodyContainer>

      <TrackTickets
        open={open}
        toggleTicketModal={toggleTicketModal}
        name={name}
        isEvent={true}
        tickets={isTickethoss ? tickets : pitpayTickets}
        deepLink={`${
          process.env.GATSBY_EXTERNAL_URL
        }?deeplink_path=${encodeURIComponent(`event/${event.id}`)}`}
        openDownloadModal={openDownloadModal}
      />

      {openDownloadApp && <DownloadApp onClose={onClose} />}

      {openInfoModal && (
        <EventInfoModal onClose={onCloseInfo} eventData={eventData} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query GetEvent($id: Int!) {
    tickethossGraphql {
      getEvent(input: { id: $id }) {
        id
        image
        infos {
          id
          link
          info {
            icon
            id
            name
          }
          logo
          text
        }
        about
        faqs {
          id
          question
          answer
        }
        facebook
        twitter
        instagram
        isMultiDay
        logo
        start_date
        soldout
        end_date
        instagram
        twitter
        facebook
        international_fee
        name
        off_sale_time
        promoter_name
        promoter_phone
        promoter_website
        promoter_email
        sale_end
        schedule_details
        admin_times {
          event_date
          gate_time
          start_time
        }
        track {
          id
          name
          url
          city
          state
          facebook_image
          twitter_image
        }
        series {
          id
          name
          logo
          facebook_image
          twitter_image
        }
        sales_status
        show_pitpay
        show_tickethoss
        status
        facebook_image
        twitter_image
        purchase_options {
          has_memberships
          has_other_tickets
          has_registrations
          has_tickets
        }
        annual_ticket_options {
          id
          name
          logo
          type
        }
        pass_options_v2 {
          id
          text
          icon
          type
        }
      }
      getGATickets(event_id: $id) {
        id
        name
        price
        track_price
        type
        fee
        description
        is_featured
        is_soldout
      }
      getEventSponsorships(event_id: $id) {
        id
        name
      }
    }
  }
`
