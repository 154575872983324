import moment from "moment"
import React from "react"
import LocationSvg from "../../images/location_orange.svg"
import {
  EventMainImageContainer,
  Image,
  EventContent,
  EventName,
  Support,
  LocationIcon,
  LocationText,
  EventLogo,
  EventLogoWrapper,
  ImageOverlayWrapper,
} from "../../styles/eventImage.style"
import {
  Date,
  DateContainerMobile,
  DayMobile,
  Ribbon,
} from "../../styles/eventList.style"
import Cancelled from "../../images/cancelled_ribbon.svg"
import Postponed from "../../images/postponed_ribbon.svg"

export default function EventImage({
  image,
  name,
  logo,
  location,
  startDate,
  endDate,
  isMultiDay,
  status,
  event,
  openInfo,
  isSeries,
}) {
  return (
    <>
      <EventMainImageContainer>
        <Image
          src={
            image
              ? image
              : "https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/ad3b0cab-c04e-402e-b0ef-a2fe03f71aff/dbfb7e89-6a43-4e6e-a2e5-d292b8371cd2?org_if_sml=1102569"
          }
          alt="large1872"
        />
        <ImageOverlayWrapper>
          <EventContent status={status}>
            {endDate ? (
              <DateContainerMobile>
                <DayMobile>
                  <p style={{ marginTop: -2 }}>
                    {isMultiDay
                      ? moment(startDate, "MM-DD-YYYY")
                          .format("MMM")
                          .toUpperCase()
                      : moment(startDate, "MM-DD-YYYY")
                          .format("ddd")
                          .toUpperCase()}
                  </p>
                </DayMobile>
                <Date>
                  {isMultiDay
                    ? `${moment(startDate, "MM-DD-YYYY").format(
                        "D"
                      )} - ${moment(endDate, "MM-DD-YYYY").format("D")}`
                    : moment(startDate, "MM-DD-YYYY")
                        .format("MMM D")
                        .toUpperCase()}
                </Date>
              </DateContainerMobile>
            ) : (
              <DateContainerMobile>
                <DayMobile>
                  <p style={{ marginTop: -2 }}>
                    {moment(startDate, "MM-DD-YYYY")
                      .format("ddd")
                      .toUpperCase()}
                  </p>
                </DayMobile>
                <Date>
                  {moment(startDate, "MM-DD-YYYY")
                    .format("MMM D")
                    .toUpperCase()}
                </Date>
              </DateContainerMobile>
            )}
            <EventName>{name}</EventName>
            {status !== "published" && !isSeries && (
              <Ribbon
                src={status === "cancelled" ? Cancelled : Postponed}
                alt="Ribbon"
                status={status}
                onClick={() =>
                  openInfo(
                    event.id,
                    event.promoter_phone,
                    event.promoter_email,
                    event.status
                  )
                }
              />
            )}
          </EventContent>
          <EventLogoWrapper>
            {logo && <EventLogo src={logo ? logo : ""} alt="large1872" />}
          </EventLogoWrapper>
        </ImageOverlayWrapper>
      </EventMainImageContainer>

      <Support>
        <LocationIcon src={LocationSvg} alt="Location Icon" />
        <LocationText>{location}</LocationText>
      </Support>
    </>
  )
}
