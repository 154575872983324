import React from "react"
import closeIcon from "../images/close.svg"
import { Style } from "../styles/downloadApp.style"

export default function EventInfoModal({ onClose, eventData }) {
  const { promoterPhone, promoterEmail, status } = eventData

  return (
    <Style style={{ maxWidth: "100vw" }}>
      <div className="overlay" onClick={onClose}></div>

      <div className="downloadModal" style={{ height: 500, top: "42%" }}>
        <div className="header">
          <img
            src={closeIcon}
            alt="Close Icon"
            onClick={onClose}
            style={{ cursor: "pointer" }}
            height={24}
            width={24}
          />
          <div className="title" style={{ fontSize: "2em" }}>
            Event {status}
          </div>
        </div>

        <div className="body">
          <div className="content">
            If you made purchase for this event, the promoter will be in touch
            in regards to refunds, credits, or rescheduling.
          </div>
          <div className="content">
            For more information please reach out to event promoter at:
          </div>
          <div className="phoneAndEmail">
            <p>{promoterPhone}</p>
            <p>or</p>
            <p style={{ wordBreak: "break-all" }}>{promoterEmail}</p>
          </div>
        </div>
      </div>
    </Style>
  )
}
