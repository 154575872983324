import React, { useState } from "react"
import {
  TicketDetail,
  ScheduleDetailDiv,
  ScheduleText,
  ScheduleDetailLabel,
} from "../../styles/ticketList.style"
import ArrowOrange from "../../images/arrow_orange.svg"
import { ArrowDown } from "../../styles/ticketAccordion.style"
import styled from "styled-components"

const ScheduleWrapper = styled.div`
  margin: 25px 0px;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`
export default function ScheduleDetail({ scheduleDetails }) {
  const [showMore, setShowMore] = useState(false)
  const toggleShowMore = () => setShowMore(showMore => !showMore)

  const scheduleDetailsArray = scheduleDetails && scheduleDetails.split(/\n/)

  return (
    <ScheduleWrapper>
      <TicketDetail className="Col" style={{ paddingLeft: 0 }}>
        <ScheduleDetailDiv onClick={toggleShowMore}>
          <ScheduleDetailLabel>{"Schedule Details"}</ScheduleDetailLabel>{" "}
          <ArrowDown
            src={ArrowOrange}
            open={showMore}
            height="8"
            width="8"
            marginTop={8}
          />
        </ScheduleDetailDiv>
        <ScheduleText style={{ marginTop: 15 }}>
          {!showMore || !scheduleDetailsArray
            ? ""
            : scheduleDetailsArray?.map(schedule => (
                <p style={{ marginBottom: 10, fontFamily: "Roboto" }}>
                  {schedule}
                </p>
              ))}
        </ScheduleText>
      </TicketDetail>
    </ScheduleWrapper>
  )
}
