import React from "react"
import moment from "moment"
import {
  MainContainer,
  NameContainer,
  ScheduleTime,
} from "../../styles/scheduleList.style"
import DateCard from "../DateCard"

export default function ScheduleList({
  date,
  isMultiDay,
  endDate,
  gateTime,
  startTime,
}) {
  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  return (
    <MainContainer className="Row">
      <DateCard isMultiday={isMultiDay} date={date} endDate={endDate} />
      <NameContainer className="Col">
        {gateTime && (
          <ScheduleTime>{isTickethoss ? "Gates" : "Pit Gate"} </ScheduleTime>
        )}
        {startTime && <ScheduleTime>Racing</ScheduleTime>}
      </NameContainer>
      <NameContainer className="Col">
        <ScheduleTime>
          {gateTime ? moment(gateTime, "HH:mm:ss").format("h:mm a") : ""}
        </ScheduleTime>
        <ScheduleTime>
          {startTime ? moment(startTime, "HH:mm:ss").format("h:mm a") : ""}
        </ScheduleTime>
      </NameContainer>
    </MainContainer>
  )
}
